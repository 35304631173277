import { UxfNextPageContext } from "@app-types/next";
import {
    ClubDetailDocument,
    ClubDetailQuery,
    ClubDetailQueryVariables,
    ClubFragment,
    ContractState,
    Maybe,
    MembershipFragment,
    MyClubsDocument,
    MyClubsQuery,
    MyClubsQueryVariables,
    MyHousesDocument,
    MyHousesQuery,
    MyHousesQueryVariables,
} from "@gql-schema";
import { RedirectError } from "@shared/error/RedirectError";
import { RedirectToUrl } from "@shared/error/RedirectToUrl";
import { ApolloErrorHelper } from "@utils/ApolloErrorHelper";
import Cookie from "@utils/cookie";
import { queryParamToNumber } from "@uxf_base/helpers/NextContextQueryHelper";
import { CommunityZone } from "../routes/routes";

const zoneKomunita: CommunityZone = "komunita";
const zoneMojeDomy: CommunityZone = "moje-domy";

const isHouse = (clubTypeId: number) => {
    return clubTypeId === 1;
};

const isSVJ = (club: { tag: { id: number } }) => {
    return club.tag.id === 1;
};

const getZone = (clubTypeId: number | undefined): CommunityZone => {
    return clubTypeId && isHouse(clubTypeId) ? "moje-domy" : "komunita";
};

const clubGetDataAndRewriteUrl = async (ctx: UxfNextPageContext): Promise<{ club: ClubFragment }> => {
    const clubId = queryParamToNumber(ctx.query.clubId);

    const { data, errors } = await ctx.apolloClient.query<ClubDetailQuery, ClubDetailQueryVariables>({
        query: ClubDetailDocument,
        variables: { clubId },
    });

    if (errors && ApolloErrorHelper.hasForbidden(errors)) {
        throw new RedirectError("club-zone/public-link-invitation", { clubId, zone: getZone(undefined) });
    }

    if ((errors && errors.length > 0) || !data || !data.club) {
        throw new RedirectError("house-zone/house-community", {});
    }

    const club: ClubFragment = data.club;

    const isHousex = isHouse(club.type.id);
    if (isHousex && ctx.query.zone === zoneKomunita && ctx.asPath) {
        const newUrl = ctx.asPath.replace(zoneKomunita, zoneMojeDomy);

        throw new RedirectToUrl(newUrl);
    }

    return {
        club,
    };
};

const cookie = new Cookie();

const lastHouseClubIdCookieName = "HouseZone-LastClubId";

const houseRedirect = async (ctx: UxfNextPageContext) => {
    const { data } = await ctx.apolloClient.query<MyHousesQuery, MyHousesQueryVariables>({
        query: MyHousesDocument,
    });

    const lastHouseClubId = cookie.get(lastHouseClubIdCookieName);

    if (lastHouseClubId !== "") {
        throw new RedirectError("club-zone/club-feed", {
            clubId: parseInt(lastHouseClubId, 10),
            zone: "moje-domy",
        });
    }

    if (!lastHouseClubId && data?.myHouses && data?.myHouses.length > 0) {
        throw new RedirectError("club-zone/club-feed", { clubId: data.myHouses[0].id, zone: "moje-domy" });
    }
};

const lastClubIdCookieName = "ClubZone-LastClubId";

const clubRedirect = async (ctx: UxfNextPageContext) => {
    const { data } = await ctx.apolloClient.query<MyClubsQuery, MyClubsQueryVariables>({
        query: MyClubsDocument,
    });

    const lastClubId = cookie.get(lastClubIdCookieName);

    if (lastClubId !== "") {
        throw new RedirectError("club-zone/club-feed", {
            clubId: parseInt(lastClubId, 10),
            zone: "komunita",
        });
    }

    if (!lastClubId && data?.myClubs && data?.myClubs.length > 0) {
        throw new RedirectError("club-zone/club-feed", { clubId: data.myClubs[0].id, zone: "komunita" });
    }
};

const formatMemberName = (...input: Array<string | null | undefined>): string => {
    return [...input].filter((value) => !!value).join(" ");
};

const formatAdress = (...input: Array<string | number | null | undefined>): string => {
    return [...input].filter((value) => !!value).join(" ");
};

const getMemberBirthDate = (membership: MembershipFragment) => {
    return membership?.profile?.birthDate
        ? membership?.profile.birthDate
        : membership?.member?.birthDate
          ? membership?.member.birthDate
          : "";
};

const formatOwnerNamesArray = (ownerNames: string[]): string => {
    return (
        ownerNames.slice(0, 2).join(", ") +
        (ownerNames.length === 3
            ? ` a ${ownerNames[2]}`
            : ownerNames.length > 5
              ? ` a ${ownerNames.length - 2} další`
              : ownerNames.length > 3
                ? ` a ${ownerNames.length - 2} dalších`
                : "")
    );
};

export const isFreezed = (club?: { contractState?: Maybe<ContractState> }) =>
    (club?.contractState && [ContractState.Freezed, ContractState.TrialEnded].includes(club.contractState)) ?? false;

export const ClubHelper = {
    clubGetDataAndRewriteUrl,
    clubRedirect,
    formatAdress,
    formatMemberName,
    formatOwnerNamesArray,
    getMemberBirthDate,
    houseRedirect,
    isHouse,
    isSVJ,
    lastClubIdCookieName,
    lastHouseClubIdCookieName,
};
