import { routeToUrl } from "@app-routes";
import { Notification } from "@components/ProfileZone/Notifications/Notification";
import { useMyNotificationBadgesQuery, useMyNotificationsQuery } from "@gql-schema";
import { useTranslation } from "@translations/use-translation";
import { GhostButton } from "@ui/GhostButton";
import { ToggleLayerBase } from "@ui/ToogleLayerBase";
import { Widget } from "@ui/Widget/Widget";
import rem from "polished/lib/helpers/rem";
import { FC, memo } from "react";
import styled, { css, useTheme } from "styled-components";

export const Menu = styled("div")(
    ({ theme }) => css`
        background-color: ${theme.color.palette.white};
        border-radius: ${rem(theme.borderRadius.default)};
        box-shadow: ${theme.shadow.dropdown};
        max-width: ${rem(372)};
        z-index: ${theme.zIndex.dropdown};
    `,
);

const HeaderNotificationsComponent: FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { data } = useMyNotificationsQuery();
    const { data: badgeData } = useMyNotificationBadgesQuery();

    if (!data || !badgeData) {
        return <GhostButton className="icon" name="notifications" disabled mr={theme.legacySpacing(3)} />;
    }

    const myNotificationBadges = badgeData.myNotificationBadges;

    const notificationsLength = data.myNotifications.length;

    const id = "notifications-popover--header-notifications";

    return (
        <ToggleLayerBase
            id={id}
            placementProps={{
                anchor: "BOTTOM_RIGHT",
            }}
            renderLayer={({ isOpen, layerProps }) => {
                return (
                    isOpen &&
                    !!badgeData && (
                        <Menu id={id} ref={layerProps.ref} style={layerProps.style}>
                            <Widget
                                contentHeight={330}
                                forwardLink={t("ui-header-notifications:show-all")}
                                forwardLinkProps={{ href: routeToUrl("profile-zone/notifications") }}
                                mobileBoxMargins={false}
                                title={t("ui-header-notifications:notification-overview")}
                            >
                                {data.myNotifications.map((notification, index) => {
                                    return (
                                        <Notification
                                            key={notification.id}
                                            notification={notification}
                                            showDivider={notificationsLength - 1 !== index}
                                        />
                                    );
                                })}
                            </Widget>
                        </Menu>
                    )
                );
            }}
            title={t("ui-header-notifications:show-notifications")}
            titleOpen={t("ui-header-notifications:hide-notifications")}
            toggleLayerProps={{
                closeOnDisappear: "full",
            }}
            triggerComponent={
                <GhostButton
                    name="notifications"
                    badge={
                        myNotificationBadges.notifications ? myNotificationBadges.notifications.toString() : undefined
                    }
                    aria-controls={id}
                    aria-haspopup={!!badgeData}
                    disabled={!badgeData || notificationsLength === 0}
                    mr={theme.legacySpacing(3)}
                />
            }
        />
    );
};

export const HeaderNotifications = memo(HeaderNotificationsComponent);
