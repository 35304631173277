import { getLandingPageMenu } from "@config/configureMenu";
import { useTranslation } from "@translations/use-translation";
import { NavBarItem } from "@ui/NavBarItem";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { useTheme } from "styled-components";

const DesktopLandingComponent: FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const router = useRouter();
    const landingPageMenu = getLandingPageMenu(t);
    const pathPart = router.asPath.split("/")[1];

    return (
        <>
            {landingPageMenu.map((menuItem, index) => {
                const { menuKey, dataTestId, ...restItemProps } = menuItem;
                return (
                    <NavBarItem
                        isActive={pathPart === menuKey}
                        key={`navBarItem${index}`}
                        ml={index > 0 ? theme.legacySpacing(6) : undefined}
                        dataTestId={`navbar-${dataTestId}`}
                        {...restItemProps}
                    >
                        {menuItem.title}
                    </NavBarItem>
                );
            })}
        </>
    );
};

export const DesktopLanding = memo(DesktopLandingComponent);

DesktopLanding.displayName = "DesktopLanding";
