import { NextPageContext } from "next";

/** @deprecated Use @uxf/core/cookie instead of this**/
export default class Cookie {
    private ctx: NextPageContext;

    constructor(ctx: any | null = null) {
        this.ctx = ctx;
    }

    public static create(ctx: any = null): Cookie {
        return new Cookie(ctx);
    }

    public exists(name: string): boolean {
        return !!this.get(name);
    }

    public set(name: string, value: string, ttl = 3600): Cookie {
        const date = new Date();
        date.setTime(date.getTime() + ttl * 1000);
        const expires = "expires=" + date.toUTCString();
        const cookie = name + "=" + encodeURIComponent(value) + ";" + expires + ";path=/";
        if (typeof document !== "undefined") {
            document.cookie = cookie;
        } else {
            const existsCookies = ((this.ctx.res?.getHeader("Set-Cookie") as string[]) || []).slice(0);
            existsCookies.push(cookie);
            // eslint-disable-next-line no-unused-expressions
            this.ctx?.res?.setHeader("Set-Cookie", existsCookies);
        }
        return this;
    }

    public delete(name: string): Cookie {
        this.set(name, "", -1);
        return this;
    }

    public get(cookieName: string): string {
        const decodedCookie =
            typeof document !== "undefined"
                ? decodeURIComponent(document.cookie)
                : decodeURIComponent(this.ctx?.req?.headers?.cookie ?? "");
        const name = cookieName + "=";
        const cookies = decodedCookie.split(";");
        for (let cookie of cookies) {
            // eslint-disable-next-line eqeqeq
            while (cookie.charAt(0) == " ") {
                cookie = cookie.substring(1);
            }
            // eslint-disable-next-line eqeqeq
            if (cookie.indexOf(name) == 0) {
                return decodeURIComponent(cookie.substring(name.length, cookie.length));
            }
        }
        return "";
    }
}
