import { getDefaultMenu } from "@config/configureMenu";
import { useTranslation } from "@translations/use-translation";
import { NavBarItem } from "@ui/NavBarItem";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { useTheme } from "styled-components";

const DesktopLoggedComponent: FC = () => {
    const { t } = useTranslation();

    const theme = useTheme();
    const router = useRouter();
    const menu = getDefaultMenu(t);
    const pathPart = router.asPath.split("/")[1];

    return (
        <>
            {menu.map((menuItem, index) => {
                const { menuKey, title, ...restItemProps } = menuItem;
                return (
                    <NavBarItem
                        isActive={pathPart === menuKey}
                        key={`navBarItem${index}`}
                        ml={index > 0 ? theme.legacySpacing(6) : undefined}
                        {...restItemProps}
                        data-testid={`navbar-${menuKey}`}
                    >
                        {title}
                    </NavBarItem>
                );
            })}
        </>
    );
};

export const DesktopLogged = memo(DesktopLoggedComponent);
DesktopLogged.displayName = "DesktopLogged";
