import { routeToUrl } from "@app-routes";
import { getDefaultMenu, getLandingPageMenu } from "@config/configureMenu";
import { DEFAULT_BOX_PADDING } from "@styles/constants";
import { useTranslation } from "@translations/use-translation";
import { Dropdown } from "@ui/Dropdown/Dropdown";
import { DropdownItemProps } from "@ui/Dropdown/types";
import { GhostButton } from "@ui/GhostButton";
import { ToolsMobile } from "@ui/Header/styles";
import { useWindowSize } from "@uxf/core/hooks/useWindowSize";
import { queryParamToString } from "@uxf_base/helpers/NextContextQueryHelper";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { useTheme } from "styled-components";

const MobileComponent: FC<{ isUserLogged?: boolean }> = (props) => {
    const { t } = useTranslation();
    const menu = getDefaultMenu(t);
    const landingPageMenu = getLandingPageMenu(t);

    const theme = useTheme();
    const { isUserLogged } = props;
    const router = useRouter();
    const redirUrl = queryParamToString(router.query.redirUrl);

    const windowSize = useWindowSize();

    const pathPart = router.asPath.split("/")[1];

    const registrationButtons: DropdownItemProps[] =
        windowSize && windowSize.width < theme.breakpoint.sm - 1
            ? [
                  {
                      icon: "shield",
                      iconTitle: t("ui-header-nav-bar-menu-mobile:login"),
                      title: t("ui-header-nav-bar-menu-mobile:login"),
                      href: routeToUrl("auth-zone/login", { redirUrl }),
                      isActive: pathPart === "login",
                  },
                  {
                      icon: "download",
                      iconTitle: t("ui-header-nav-bar-menu-mobile:register"),
                      title: t("ui-header-nav-bar-menu-mobile:register"),
                      href: routeToUrl("auth-zone/registration", { redirUrl }),
                      isActive: pathPart === "registrace",
                  },
              ]
            : [];

    return (
        <ToolsMobile
            alignItems="center"
            boxWidth="auto"
            flexShrink="0"
            pl={DEFAULT_BOX_PADDING}
            pr={DEFAULT_BOX_PADDING}
            permanentlyHidden={{ condition: true, from: "lg" }}
        >
            <Dropdown
                id="header-avatar-menu"
                data-testid="header-avatar-menu"
                triggerComponent={
                    <GhostButton
                        className="icon icon--hamburger"
                        name="hamburger"
                        title={t("ui-header-nav-bar-menu-mobile:open-menu")}
                    />
                }
                items={[
                    ...(isUserLogged
                        ? menu.map((item) => {
                              return {
                                  icon: item.icon,
                                  iconTitle: item.title,
                                  title: item.title,
                                  href: item.href,
                                  isActive: pathPart === item.menuKey,
                                  endComponent: item.chip ? (
                                      <span className="ml-2 rounded-full bg-primary-light px-2 py-0.5 text-xs text-primary">
                                          {item.chip}
                                      </span>
                                  ) : undefined,
                              };
                          })
                        : [
                              ...landingPageMenu.map((item) => {
                                  return {
                                      icon: item.icon,
                                      iconTitle: item.title,
                                      title: item.title,
                                      href: item.href,
                                      isActive: pathPart === item.menuKey,
                                      endComponent: item.chip ? (
                                          <span className="ml-2 rounded-full bg-primary-light px-2 py-0.5 text-xs text-primary">
                                              {item.chip}
                                          </span>
                                      ) : undefined,
                                  };
                              }),
                              ...registrationButtons,
                          ]),
                ]}
                placementProps={{ triggerOffset: 0 }}
            />
        </ToolsMobile>
    );
};

export const Mobile = memo(MobileComponent);

Mobile.displayName = "Mobile";
