import { config } from "@config/config";
import { AppInstance } from "@shared/utils/app-instance";

export const defaultMetaTagData = {
    ogTitle: "Bydlení s námi je radost",
    description: "Přinášíme usnadnění, komfort a benefity pro sousedy, předsedy SVJ/BD a správce",
    fbAppId: config.FACEBOOK_APP_ID,
    image: "/static/images/og/default.jpg" /* (relative eg. "/static/fb-og-image.jpg") */,
    siteName: AppInstance.isSlovak ? "Susedia.sk" : "Sousedé.cz",
    type: "website",
};
