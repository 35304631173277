import { isBrowser } from "@uxf_base/utils/isBrowser";

const addMaximumScaleToMetaViewport = () => {
    const element = isBrowser ? document.querySelector("meta[name=viewport]") : null;

    if (element !== null) {
        let content = element.getAttribute("content");
        const re = /maximum-scale=[0-9.]+/g;

        if (content && re.test(content)) {
            content = content.replace(re, "maximum-scale=1.0");
        } else {
            content = [content, "maximum-scale=1.0"].join(", ");
        }

        element.setAttribute("content", content);
    }
};

export const ViewPortHelper = {
    addMaximumScaleToMetaViewport,
};
