import { MutationResult } from "@apollo/client/react";
import * as GQL from "@gql-schema";
import { Refetch, SetNotificationsViewedMutation, SetNotificationsViewedMutationVariables } from "@gql-schema";
import { ExecutionResult } from "graphql/execution";
import { useCallback } from "react";

// INFO: THIS HOOK IS COMPLETED AND USED
type HookCallback = (
    variables: SetNotificationsViewedMutationVariables,
) => Promise<ExecutionResult<SetNotificationsViewedMutation>>;
type Hook = () => [HookCallback, MutationResult<SetNotificationsViewedMutation>];

export const useSetNotificationsViewedMutation: Hook = () => {
    const [executor, params] = GQL.useSetNotificationsViewedMutation();

    const mutation: HookCallback = useCallback(
        (variables) => {
            return executor({
                variables,
                refetchQueries: [Refetch.MyNotificationBadges({}), Refetch.MyNotifications({})],
            });
        },
        [executor],
    );

    return [mutation, params];
};
