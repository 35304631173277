import { routeToUrl } from "@app-routes";
import { Link } from "@ui/Link";
import { ReactNode } from "react";

export const configureInfoBar: ReactNode = (
    <>
        Hledáte profesionálního předsedu? Pomůžeme Vám!{" "}
        <Link underline href={routeToUrl("landing-zone/chairman-pro")}>
            Více info zde.
        </Link>
    </>
);
