import { routeToUrl } from "@app-routes";
import { IconsType } from "@config/icons";
import { NotificationContentType, NotificationFragment } from "@gql-schema";
import { ClubHelper } from "@utils/ClubHelper";
import { Url } from "next/dist/shared/lib/router/router";
import { TranslateFunction } from "../../next-translate";

export const P_MESSAGES_NOTIFICATION = 0b1;
export const P_EVENTS_COMING_NOTIFICATION = 0b10;
export const P_MY_POSTS_NOTIFICATION = 0b100;
export const P_WEEKLY_REPORT = 0b1000;
export const P_EVENTS_CANCELED_NOTIFICATION = 0b10000;
export const P_MESSAGES_EMAIL = 0b100000;
export const P_EVENTS_COMING_EMAIL = 0b1000000;
export const P_MY_POSTS_EMAIL = 0b10000000;
export const P_EVENTS_CANCELED_EMAIL = 0b100000000;

export const M_POST_NOTIFICATION = 0b1;
export const M_EVENT_NOTIFICATION = 0b10;
export const M_DEFECT_NOTIFICATION = 0b100;
export const M_SURVEY_NOTIFICATION = 0b1000;
export const M_DOCUMENT_NOTIFICATION = 0b100000000;
export const M_POST_EMAIL = 0b10000;
export const M_EVENT_EMAIL = 0b100000;
export const M_DEFECT_EMAIL = 0b1000000;
export const M_SURVEY_EMAIL = 0b10000000;
export const M_DOCUMENT_EMAIL = 0b1000000000;

export const CLUB_NOTIFICATIONS = (
    t: TranslateFunction,
): { title: string; email: number; notification: number; onlyHouse?: boolean }[] => [
    {
        title: t("component-club-house-notifications:new-board-message"),
        email: M_POST_EMAIL,
        notification: M_POST_NOTIFICATION,
    },
    {
        title: t("component-club-house-notifications:new-calendar-event"),
        email: M_EVENT_EMAIL,
        notification: M_EVENT_NOTIFICATION,
    },
    {
        title: t("component-club-house-notifications:defect-created"),
        email: M_DEFECT_EMAIL,
        notification: M_DEFECT_NOTIFICATION,
        onlyHouse: true,
    },
    {
        title: t("component-club-house-notifications:new-document"),
        email: M_DOCUMENT_EMAIL,
        notification: M_DOCUMENT_NOTIFICATION,
    },
];

export interface INotificationRoute {
    name: Url | null;
}

// TODO routes + params
// eslint-disable-next-line complexity
export const resolveNotificationRoute = (notification: NotificationFragment): INotificationRoute => {
    const isHouse = ClubHelper.isHouse(notification.clubType);

    switch (notification.contentType) {
        case NotificationContentType.Post:
            return {
                name: routeToUrl("wall-zone/club-post-detail", {
                    clubId: notification.clubId,
                    postId: notification.contentId,
                    zone: isHouse ? "moje-domy" : "komunita",
                }),
            };
        case NotificationContentType.EventCreated:
        case NotificationContentType.EventEdited:
        case NotificationContentType.EventRemoved:
            return {
                name: routeToUrl("club-zone/club-events", {
                    clubId: notification.clubId,
                    zone: isHouse ? "moje-domy" : "komunita",
                }),
            };
        case NotificationContentType.EventComing:
            return {
                name: routeToUrl("club-zone/club-events", {
                    clubId: notification.clubId,
                    zone: isHouse ? "moje-domy" : "komunita",
                }),
            };
        case NotificationContentType.DefectCreated:
            return { name: routeToUrl("defect-zone/defects", { clubId: notification.clubId }) };
        case NotificationContentType.ClubInvitation:
            return {
                name: routeToUrl(notification.clubType <= 2 ? "house-zone/house-community" : "club-zone/community"),
            };
        case NotificationContentType.ClubRequest:
            return {
                name: routeToUrl(notification.clubType <= 2 ? "house-zone/house-members" : "club-zone/club-members", {
                    clubId: notification.clubId,
                }),
            };
        case NotificationContentType.ClubRequestApproved:
            return {
                name: routeToUrl("club-zone/club-detail", {
                    clubId: notification.clubId,
                    zone: isHouse ? "moje-domy" : "komunita",
                }),
            };
        case NotificationContentType.Club:
            return {
                name: routeToUrl("club-zone/club-detail", {
                    clubId: notification.clubId,
                    zone: isHouse ? "moje-domy" : "komunita",
                }),
            };
        case NotificationContentType.Vote:
            return {
                name: routeToUrl("vote-zone/vote-detail", {
                    clubId: notification.clubId,
                    voteId: notification.contentId,
                }),
            };
        case NotificationContentType.VoteSk:
            switch (notification.contentId) {
                case 76:
                    return {
                        name: routeToUrl("vote-zone-sk/vote-detail", {
                            clubId: notification.clubId,
                            voteId: notification.contentId,
                        }),
                    };
                default:
                    return {
                        name: routeToUrl("vote-zone-sk/vote-voting", {
                            clubId: notification.clubId,
                            voteId: notification.contentId,
                        }),
                    };
            }
        case NotificationContentType.DocumentCreated:
            return {
                name: routeToUrl("document-zone/document-folder", {
                    clubId: notification.clubId,
                    folderId: notification.contentId,
                    zone: isHouse ? "moje-domy" : "komunita",
                }),
            };
        case NotificationContentType.Unknown:
            return {
                name: routeToUrl("club-zone/club-detail", {
                    clubId: notification.clubId,
                    zone: isHouse ? "moje-domy" : "komunita",
                }),
            };
        default:
            return { name: null };
    }
};

export const resolveNotificationIcon = (notification: NotificationFragment): IconsType => {
    switch (notification.contentType) {
        case NotificationContentType.Post:
            return "comment";
        case NotificationContentType.EventCreated:
        case NotificationContentType.EventEdited:
        case NotificationContentType.EventComing:
            return "calendar";
        case NotificationContentType.ClubInvitation:
            return "blockOfFlats";
        case NotificationContentType.ClubRequest:
            return "user";
        case NotificationContentType.ClubRequestApproved:
            return "blockOfFlats";
        case NotificationContentType.Club:
            return "check";
        case NotificationContentType.DocumentCreated:
            return "filePlus";
        default:
            return "cross";
    }
};
