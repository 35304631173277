import { getUserMenu } from "@config/configureMenu";
import { useMyProfileQuery } from "@gql-schema";
import { DEFAULT_BOX_PADDING } from "@styles/constants";
import { useTranslation } from "@translations/use-translation";
import { Avatar } from "@ui/Avatar";
import { Dropdown } from "@ui/Dropdown/Dropdown";
import { HeaderMessages } from "@ui/Header/HeaderMessages";
import { HeaderNotifications } from "@ui/Header/HeaderNotifications";
import { ToolsDesktop } from "@ui/Header/styles";
import { useRouter } from "next/router";
import { FC, memo } from "react";

const HeaderProfileComponent: FC = () => {
    const { t } = useTranslation();
    const { data } = useMyProfileQuery();
    const router = useRouter();

    if (!data) {
        return null;
    }

    const me = data.me;

    const pathParts = router.asPath.split("/");
    const pathPart = `${pathParts[1]}/${pathParts[2]}`;

    const userMenu = getUserMenu(t, data.me).map((item) => {
        return {
            icon: item.icon,
            iconTitle: item.title,
            onClick: item.onClick,
            href: item.href,
            title: item.title,
            isActive: pathPart === item.menuKey,
        };
    });

    return (
        <ToolsDesktop
            alignItems="center"
            boxWidth="auto"
            flexShrink="0"
            pl={DEFAULT_BOX_PADDING}
            pr={DEFAULT_BOX_PADDING}
        >
            <HeaderMessages />
            <HeaderNotifications />
            <Dropdown
                id="header-avatar-menu"
                data-testid="header-avatar-menu"
                title={t("ui-header-profile:open-menu")}
                titleOpen={t("ui-header-profile:close-menu")}
                triggerComponent={
                    <Avatar
                        className="icon"
                        src={me.avatar ?? undefined}
                        placeholder
                        size="size40"
                        title={me.fullName}
                    />
                }
                items={userMenu}
                placementProps={{ triggerOffset: 0 }}
            />
        </ToolsDesktop>
    );
};

export const HeaderProfile = memo(HeaderProfileComponent);
