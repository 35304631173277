import { Box, IBoxProps } from "@ui/Box";
import { TextSmall } from "@ui/Text";
import { CssHelper } from "@utils/CssHelper";
import styled, { css } from "styled-components";

export const Root = styled(Box)<IBoxProps>(
    ({ theme }) => css`
        border-top: ${CssHelper.withUnit(theme.border.default, "px")} solid ${theme.color.palette.border};
    `,
);

export const MenuItem = styled(TextSmall)`
    break-inside: avoid;
`;

MenuItem.defaultProps = {
    as: "div",
    mb: 12,
    role: "listitem",
    textColor: "textMuted",
    textFontWeight: "medium",
};
