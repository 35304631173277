import { routeToUrl } from "@app-routes";
import { useMeNullable } from "@profile-zone/hooks/use-me-nullable";
import { AppInstance } from "@shared/utils/app-instance";
import { DEFAULT_BOX_PADDING, NAVBAR_ID } from "@styles/constants";
import { Box } from "@ui/Box";
import { FlexBox } from "@ui/FlexBox";
import { HeaderLoginRegistration } from "@ui/Header/HeaderLoginRegistration";
import { HeaderProfile } from "@ui/Header/HeaderProfile";
import { Mobile } from "@ui/Header/NavBarMenu/Mobile";
import { InfoBar } from "@ui/InfoBar";
import { Logo, LogoMobile } from "@ui/Logo/Logo";
import { NewClickable } from "@ui/NewClickable";
import { Hide } from "@uxf/core-react/components/hide";
import { useWindowScroll } from "@uxf/core-react/hooks/use-window-scroll";
import { useWindowSize } from "@uxf/core-react/hooks/use-window-size";
import { isNotNil } from "@uxf/core/utils/is-not-nil";
import { useTheme } from "styled-components";
import { LanguageSwitcher } from "./LanguageSwitcher";
import { DesktopLanding } from "./NavBarMenu/DesktopLanding";
import { DesktopLogged } from "./NavBarMenu/DesktopLogged";
import { Content, Root } from "./styles";
import { HeaderProps } from "./types";

export function Header(props: HeaderProps) {
    const theme = useTheme();

    const {
        customHeaderContent,
        fixedNavBar = true,
        hideInfoBar,
        hideNavBar,
        hideTools,
        logoDisabled = false,
        ...restProps
    } = props;

    const me = useMeNullable();
    const isUserLogged = isNotNil(me);

    const windowScroll = useWindowScroll();
    const windowSize = useWindowSize();

    const fixNav = windowScroll && windowScroll.y > 200;
    const showFixNav = windowSize && windowSize.width > theme.breakpoint.sm;

    const logoRoute = routeToUrl(isUserLogged ? "house-zone/house-community" : "index");

    return (
        <Root as="header" backgroundColor="white" boxPosition="relative" flexShrink="0" id={NAVBAR_ID} {...restProps}>
            <Hide when={hideInfoBar || AppInstance.isSlovak}>
                <InfoBar />
            </Hide>
            <Box as="nav" boxHeight={theme.size.toolbar}>
                <Content
                    backgroundColor="white"
                    boxHeight={theme.size.toolbar}
                    boxPosition="relative"
                    justifyContent="space-between"
                    style={{
                        ...(fixedNavBar && fixNav && showFixNav ? { left: 0, position: "fixed", top: 0 } : {}),
                    }}
                >
                    <FlexBox
                        alignItems="center"
                        boxWidth={["auto", null, null, null, null, 400]}
                        flexShrink="0"
                        pl={DEFAULT_BOX_PADDING}
                        pr={DEFAULT_BOX_PADDING}
                    >
                        <NewClickable href={!logoDisabled ? logoRoute : undefined}>
                            {isUserLogged && (
                                <LogoMobile
                                    boxHeight={44}
                                    boxWidth={34}
                                    id="header-logo-mobile"
                                    permanentlyHidden={{ condition: true, from: "sm" }}
                                    preserveAspectRatio="xMinYMid slice"
                                    data-noprint
                                />
                            )}
                            <Logo
                                boxHeight={44}
                                boxWidth={138}
                                id="header-logo"
                                permanentlyHidden={isUserLogged ? { condition: true, to: "sm" } : undefined}
                                preserveAspectRatio="xMinYMid slice"
                            />
                        </NewClickable>
                    </FlexBox>
                    {!hideNavBar && (
                        <FlexBox justifyContent="center" permanentlyHidden={{ condition: true, to: "lg" }} data-noprint>
                            {isUserLogged ? <DesktopLogged /> : <DesktopLanding />}
                        </FlexBox>
                    )}
                    <FlexBox
                        boxWidth={["auto", null, null, null, null, !customHeaderContent ? 400 : null]}
                        flexShrink="0"
                        justifyContent="flex-end"
                        data-noprint
                    >
                        {customHeaderContent}
                        {isUserLogged ? !hideTools && <HeaderProfile /> : !hideNavBar && <HeaderLoginRegistration />}
                        <LanguageSwitcher />
                        {!hideNavBar && <Mobile isUserLogged={isUserLogged} />}
                    </FlexBox>
                </Content>
            </Box>
        </Root>
    );
}
