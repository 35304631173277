import { config } from "@config/config";
import { useGtmScript } from "@uxf/analytics/gtm";
import { isServer } from "@uxf/core/utils/isServer";
import Head from "next/head";
import { FC, memo } from "react";

const LayoutGAComponent: FC = () => {
    const gtmScript = useGtmScript(config.GTM_ID as string);

    return <Head>{config.GTM_ID && !isServer && <script dangerouslySetInnerHTML={{ __html: gtmScript }} />}</Head>;
};

export const LayoutGA = memo(LayoutGAComponent);

LayoutGA.displayName = "LayoutGA";
