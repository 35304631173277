// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
import { isBrowser } from "@uxf_base/utils/isBrowser";

const checkIsIOS = () => isBrowser && /iPad|iPhone|iPod/.test(navigator.userAgent);
const checkIsMobileIOS = () => isBrowser && /iPhone|iPod/.test(navigator.userAgent);
const checkIsAndroid = () => isBrowser && /(android)/i.test(navigator.userAgent);

export const DeviceHelper = {
    checkIsIOS,
    checkIsMobileIOS,
    checkIsAndroid,
};
