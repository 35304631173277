import { configureInfoBar } from "@config/configureInfoBar";
import { Box } from "@ui/Box";
import { Container } from "@ui/Container";
import { FlexBox } from "@ui/FlexBox";
import { TextMedium } from "@ui/Text";
import { FC } from "react";
import { useTheme } from "styled-components";

export const InfoBar: FC = () => {
    const theme = useTheme();

    return (
        <Box as="aside" backgroundColor="primary" data-noprint>
            <Container boxPosition="relative">
                <FlexBox alignItems="center" minHeight={theme.size.infoBar} justifyContent="center" pb={8} pt={8}>
                    <TextMedium as="p" textAlign="center" textColor="white" textFontWeight="medium">
                        {configureInfoBar}
                    </TextMedium>
                </FlexBox>
            </Container>
        </Box>
    );
};
