import { defaultMetaTagData } from "@config/defaultMetaTagData";
import { SHOW_INFOBAR } from "@styles/constants";
import { useTranslation } from "@translations/use-translation";
import { CookieConsent } from "@ui/CookiesConsent/CookieConsent";
import { FlexBox } from "@ui/FlexBox";
import { Footer } from "@ui/Footer/Footer";
import { Header } from "@ui/Header/Header";
import { Prefetch } from "@ui/Layout/Prefetch";
import { DeviceHelper } from "@utils/DeviceHelper";
import { ViewPortHelper } from "@utils/ViewPortHelper";
import Head from "next/head";
import { FC, PropsWithChildren, useEffect } from "react";
import { LayoutFavicon } from "./LayoutFavicon";
import { LayoutGA } from "./LayoutGA";
import { LayoutOG } from "./LayoutOG";
import { ILayoutProps } from "./types";

export const Layout: FC<PropsWithChildren<ILayoutProps>> = (props) => {
    const { t } = useTranslation();
    const {
        backgroundColor = "bgLight",
        children,
        customHeaderContent,
        fixedNavBar = false,
        hideFooter,
        hideFooterVisually,
        hideNavBar,
        hideInfoBar = !SHOW_INFOBAR,
        hideTools,
        logoDisabled,
        ogData,
        title,
        canonicalUrl,
        preloadImgUrl,
    } = props;

    /* prevent auto page zoom on focus in form inputs on iOS
     * (this because font-size of inputs or selects is less than 16px) */
    useEffect(() => {
        if (DeviceHelper.checkIsIOS()) {
            ViewPortHelper.addMaximumScaleToMetaViewport();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Head>
                <title>{`${title ? title + " | " : ""}` + defaultMetaTagData.siteName}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
                <meta
                    name="description"
                    content={!!ogData && !!ogData.description ? ogData.description : defaultMetaTagData.description}
                />
                <meta name="keywords" content="" />
                <link
                    rel="alternate"
                    type="application/rss+xml"
                    title={t("ui-layout:title")}
                    href="https://www.sousede.cz/api/rss/magazine.rss"
                />
                {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
                {preloadImgUrl && <link rel="preload" href={preloadImgUrl} as="image" />}
            </Head>
            <Prefetch />
            <LayoutFavicon />
            <LayoutGA />
            <LayoutOG ogData={ogData} />
            <FlexBox backgroundColor={backgroundColor} minHeight="100vh">
                <FlexBox flexDirection="column" minHeight="100vh">
                    <Header
                        customHeaderContent={customHeaderContent}
                        fixedNavBar={fixedNavBar}
                        hideInfoBar={hideInfoBar}
                        hideTools={hideTools}
                        hideNavBar={hideNavBar}
                        logoDisabled={logoDisabled}
                    />
                    <FlexBox as="main" flexDirection="column" flexGrow="1">
                        {children}
                    </FlexBox>
                    {!hideFooter && <Footer visuallyHidden={hideFooterVisually} />}
                </FlexBox>
            </FlexBox>
            <CookieConsent />
        </>
    );
};
