import { config } from "@config/config";
import { defaultMetaTagData } from "@config/defaultMetaTagData";
import { IOgDataProps } from "@ui/Layout/types";
import Head from "next/head";
import { useRouter } from "next/router";
import { FC, memo } from "react";

interface Props {
    ogData?: IOgDataProps;
}

const LayoutOGComponent: FC<Props> = (props) => {
    const { ogData = {} } = props;
    const { asPath } = useRouter();

    const url = config.FRONTEND_URL + asPath;
    const image = ogData.image ? config.FRONTEND_URL + ogData.image : config.FRONTEND_URL + defaultMetaTagData.image;
    const title = ogData.ogTitle ? ogData.ogTitle : defaultMetaTagData.ogTitle;
    const description = ogData.description ? ogData.description : defaultMetaTagData.description;

    return (
        <Head>
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={url} />
            <meta property="og:site_name" content={defaultMetaTagData.siteName} />
            <meta property="fb:app_id" content={defaultMetaTagData.fbAppId} />
            <meta property="og:type" content={ogData.type ? ogData.type : defaultMetaTagData.type} />
            {ogData.additionalMeta}
            {ogData.twitterCard && <meta name="twitter:card" content={ogData.twitterCard} />}
            {ogData.twitterImageAlt && <meta name="twitter:image:alt" content={ogData.twitterImageAlt} />}
            {ogData.twitterUserName && <meta name="twitter:site" content={ogData.twitterUserName} />}
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
        </Head>
    );
};

export const LayoutOG = memo(LayoutOGComponent);

LayoutOG.displayName = "LayoutOG";
