import { routeToUrl } from "@app-routes";
import { trackEvent } from "@services/analytics/trackEvent";
import { DEFAULT_BOX_PADDING } from "@styles/constants";
import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { Button } from "@ui/Button";
import { FlexBox } from "@ui/FlexBox";
import { queryParamToString } from "@uxf_base/helpers/NextContextQueryHelper";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { useTheme } from "styled-components";

const HeaderLoginRegistrationComponent: FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const router = useRouter();
    const redirUrl = queryParamToString(router.query.redirUrl);

    return (
        <FlexBox
            alignItems="center"
            boxWidth="auto"
            flexShrink="0"
            pl={DEFAULT_BOX_PADDING}
            pr={DEFAULT_BOX_PADDING}
            permanentlyHidden={{ condition: true, to: "sm" }}
        >
            <Button
                kind="text"
                href={routeToUrl("auth-zone/login", { redirUrl })}
                size="medium"
                variant="text"
                width={152}
                data-testid="header-login-button"
            >
                {t("ui-header-login-registration:login")}
            </Button>
            <Box boxWidth="auto" ml={theme.legacySpacing(1)}>
                <Button
                    analyticsCallback={trackEvent("Registrace", "Navigace", "Navigace")}
                    kind="default"
                    href={routeToUrl("auth-zone/registration", { redirUrl })}
                    shadow
                    size="medium"
                    variant="primary"
                    width={152}
                >
                    {t("ui-header-login-registration:register")}
                </Button>
            </Box>
        </FlexBox>
    );
};

export const HeaderLoginRegistration = memo(HeaderLoginRegistrationComponent);
HeaderLoginRegistration.displayName = "HeaderLoginRegistration";
