import { NotificationFragment } from "@gql-schema";
import { useSetNotificationsViewedMutation } from "@hooks/mutations/useSetNotificationsViewedMutation";
import { Avatar } from "@ui/Avatar";
import { ListItem } from "@ui/ListItem";
import { TextCaption, TextMedium } from "@ui/Text";
import { resolveNotificationIcon, resolveNotificationRoute } from "@utils/notificationHelper";
import moment from "moment";
import { useRouter } from "next/router";
import { FC, memo, useCallback } from "react";

interface IProps {
    notification: NotificationFragment;
    showDivider?: boolean;
}

const NotificationComponent: FC<IProps> = (props) => {
    const { notification, showDivider } = props;

    const router = useRouter();

    const [setViewed] = useSetNotificationsViewedMutation();

    const href = resolveNotificationRoute(notification);
    const icon = resolveNotificationIcon(notification);

    const handleNotificationClick = useCallback(async () => {
        await setViewed({});
        if (href.name) {
            await router.push(href.name);
        }
    }, [href.name, router, setViewed]);

    const iconElement = (
        <Avatar size="size40" src={notification.image || undefined} placeholderProps={{ name: icon }} mr={12} />
    );

    return (
        <ListItem divider={showDivider} size="large" startAdornment={iconElement} onClick={handleNotificationClick}>
            <TextMedium
                textColor={notification.viewed ? "textMuted" : null}
                textFontWeight={!notification.viewed ? "medium" : null}
            >
                {notification.text}
            </TextMedium>
            <TextCaption textColor="textMuted">{moment(notification.updatedAt).fromNow()}</TextCaption>
        </ListItem>
    );
};

export const Notification = memo(NotificationComponent);
