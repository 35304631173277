import { config } from "@config/config";
import { EventData } from "./types";

const gtmEvent = (gtmEventData: EventData) => {
    if (typeof window.dataLayer !== "undefined") {
        window.dataLayer.push(gtmEventData);
    }
};

export const event = (eventData: EventData): void => {
    if (config.STAGE !== "prod") {
        // eslint-disable-next-line no-console
        console.log("DEBUG ANALYTICS EVENT DATA: ", eventData);
    }
    gtmEvent(eventData);
};
