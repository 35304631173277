import { routeToUrl } from "@app-routes";
import { useMyNotificationBadgesQuery } from "@gql-schema";
import { useTranslation } from "@translations/use-translation";
import { GhostButton } from "@ui/GhostButton";
import { FC, memo } from "react";
import { useTheme } from "styled-components";

const HeaderMessagesComponent: FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { data } = useMyNotificationBadgesQuery();

    const myNotificationBadges = data?.myNotificationBadges;

    return (
        <GhostButton
            badge={myNotificationBadges?.messages ? myNotificationBadges.messages.toString() : undefined}
            name="sendFilled"
            mr={theme.legacySpacing(3)}
            href={routeToUrl("messenger-zone/messenger")}
            title={t("ui-header-messages:messages")}
            data-testid="navbar-messages"
        />
    );
};

export const HeaderMessages = memo(HeaderMessagesComponent);
