import {
    FOCUS_VISIBLE_CLASSNAME,
    IS_ACTIVE_CLASSNAME,
    IS_HOVERABLE_CLASSNAME,
    LINK_HOVER_OPACITY,
} from "@styles/constants";
import { marginMixin } from "@styles/newMixins";
import { Badge, IBadgeProps } from "@ui/Badge";
import { NewClickable } from "@ui/NewClickable";
import { ITextProps, TextLarge } from "@ui/Text";
import { CssHelper } from "@utils/CssHelper";
import rem from "polished/lib/helpers/rem";
import styled, { css } from "styled-components";
import { ItemRootProps } from "./types";

export const ItemText = styled(TextLarge)<ITextProps>`
    transition: ${CssHelper.transition("color")}, ${CssHelper.transition("opacity")},
        ${CssHelper.transition("textDecorationColor")};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ItemRoot = styled(({ ml, mr, ...restProps }: ItemRootProps) => (
    <NewClickable {...restProps} />
))<ItemRootProps>(
    ({ ml, mr, theme }) => css`
        align-items: center;
        color: ${theme.color.palette.textMuted};
        display: inline-flex;
        justify-content: center;
        position: relative;
        transition: ${CssHelper.transition("color")};

        ${marginMixin({ mb: undefined, ml, mr, mt: undefined })};

        &::after {
            border-bottom: ${CssHelper.withUnit(theme.border.thick, "px")} solid ${theme.color.palette.primary};
            bottom: ${CssHelper.withUnit(-theme.border.default, "px")};
            content: "";
            display: block;
            position: absolute;
            transform: scaleX(0);
            transition: ${CssHelper.transition("transform")};
            width: 100%;
        }

        &.${IS_ACTIVE_CLASSNAME} {
            color: ${theme.color.palette.text};

            &::after {
                transform: none;
            }
        }

        &.${FOCUS_VISIBLE_CLASSNAME} {
            ${ItemText} {
                text-decoration: underline;
            }
        }

        &.${IS_HOVERABLE_CLASSNAME}:hover, &.${IS_HOVERABLE_CLASSNAME}:active {
            ${ItemText} {
                opacity: ${LINK_HOVER_OPACITY};
            }

            &::after {
                transform: none;
            }
        }
    `,
);

export const ItemBadge = styled(Badge)<IBadgeProps>(
    ({ theme }) => css`
        && {
            position: absolute;
            right: ${rem(-(theme.size.badge + theme.legacySpacing(0.5)))};
            top: calc(50% - ${rem(theme.size.badge / 2)});
        }
    `,
);
