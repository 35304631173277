import { ICONS_VERSION } from "@config/icons";
import { ICON_SPRITE } from "@config/icons-config";
import Head from "next/head";
import { FC, memo } from "react";

const PrefetchComponent: FC = () => {
    return (
        <Head>
            <link
                rel="preload"
                href="/static/fonts/work-sans/work-sans-v5-latin_latin-ext-regular.woff2"
                as="font"
                crossOrigin="anonymous"
            />
            <link
                rel="preload"
                href="/static/fonts/work-sans/work-sans-v5-latin_latin-ext-500.woff2"
                as="font"
                crossOrigin="anonymous"
            />
            <link
                rel="preload"
                href="/static/fonts/work-sans/work-sans-v5-latin_latin-ext-600.woff2"
                as="font"
                crossOrigin="anonymous"
            />
            <link rel="preload" href={`${ICON_SPRITE}?v=${ICONS_VERSION}`} as="image" crossOrigin="anonymous" />
            <link rel="preconnect" href="https://connect.facebook.net" />
            <link rel="preconnect" href="https://www.googletagmanager.com" />
            <link rel="preconnect" href="https://track.adform.net" />
            <link rel="preconnect" href="https://js.hs-scripts.com" />
            <link rel="preconnect" href="https://s1.adform.net" />
            <link rel="preconnect" href="https://js.hs-analytics.net" />
            <link rel="preconnect" href="https://js.hs-banner.net" />
            <link rel="preconnect" href="https://js.hscollectedforms.net" />
            <link rel="preconnect" href="https://js.usemessages.com" />
            <link rel="preconnect" href="https://forms.hubspot.com" />
            <link rel="preconnect" href="https://api.hubspot.com" />
            <link rel="preconnect" href="https://app.hubspot.com" />
        </Head>
    );
};

/*

            <link rel="preconnect" href="https://www.google-analytics.com" />
            <link rel="preconnect" href="https://c.imedia.cz" />
            <link rel="preconnect" href="https://static.hotjar.com" />
            <link rel="preconnect" href="https://in.hotjar.com" />
            <link rel="preconnect" href="https://script.hotjar.com" />
            <link rel="preconnect" href="https://vars.hotjar.com" />
            <link rel="preconnect" href="https://vc.hotjar.io" />
            <link rel="preconnect" href="https://c.seznam.cz" />
            <link rel="preconnect" href="https://www.google.com" />
            <link rel="preconnect" href="https://www.facebook.com" />
            <link rel="preconnect" href="https://static.hsappstatic.net" />
            <link rel="preconnect" href="https://connect.facebook.net" />
            <link rel="preconnect" href="https://script.hotjar.com" />
            <link rel="preconnect" href="https://js.hs-banner.com" />
            <link rel="preconnect" href="https://track.hubspot.com" />
            <link rel="preconnect" href="https://stats.g.doubleclick.net" />

 */

export const Prefetch = memo(PrefetchComponent);

Prefetch.displayName = "Prefetch";
