import { event } from "./event";
import { EventData } from "./types";

export const trackEvent =
    (
        category: EventData["eventCategory"],
        action: EventData["eventAction"],
        label: EventData["eventLabel"],
        value?: EventData["eventValue"],
    ) =>
    () =>
        event({
            event: "trackEvent",
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            eventValue: value,
        });
