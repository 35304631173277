import { GraphQLError } from "graphql/error";

const hasForbidden = (errors: readonly GraphQLError[]): boolean => {
    return !!errors.find((e) => e.extensions?.code === "FORBIDDEN");
};

const hasEntityDeleted = (errors: readonly GraphQLError[]): boolean => {
    return !!errors?.find((e) => e.extensions?.code === "ENTITY_DELETED");
};

export const ApolloErrorHelper = {
    hasForbidden,
    hasEntityDeleted,
};
