import { IS_ACTIVE_CLASSNAME } from "@styles/constants";
import clsx from "clsx";
import { FC } from "react";
import { ItemBadge, ItemRoot, ItemText } from "./styles";
import { NavBarItemProps } from "./types";

export const NavBarItem: FC<NavBarItemProps> = (props) => {
    const { badge, children, isActive, chip, ...restProps } = props;

    return (
        <ItemRoot {...restProps} className={clsx(isActive && IS_ACTIVE_CLASSNAME, props.className)}>
            <ItemText textAlign="center" textFontWeight="medium" truncate>
                {children}
            </ItemText>
            {badge && <ItemBadge>{badge}</ItemBadge>}
            {chip && (
                <span className="ml-2 rounded-full bg-primary-light px-2 py-0.5 text-xs text-primary">{chip}</span>
            )}
        </ItemRoot>
    );
};
