import { routeToUrl } from "@app-routes";
import { IconSizeEnum } from "@config/icons";
import { AppInstance } from "@shared/utils/app-instance";
import { useTranslation } from "@translations/use-translation";
import { Box } from "@ui/Box";
import { Container } from "@ui/Container";
import { FlexBox } from "@ui/FlexBox";
import { Grid } from "@ui/Grid";
import { Icon } from "@ui/Icon";
import { Link } from "@ui/Link";
import { Logo } from "@ui/Logo";
import { Text, TextLarge } from "@ui/Text";
import { UrlHelper } from "@utils/UrlHelper";
import { Hide } from "@uxf/core-react/components/hide";
import { spacing } from "@uxf/styles/units/spacing";
import { useRouter } from "next/router";
import { FC } from "react";
import { useTheme } from "styled-components";
import { MenuItem, Root } from "./styles";
import { IFooterProps } from "./types";

export const Footer: FC<IFooterProps> = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { containerMaxWidth, ...restProps } = props;

    const { route } = useRouter();

    return (
        <Root backgroundColor="bg" as="footer" mt={[48, null, 96]} pb={spacing(5)} pt={spacing(5)} {...restProps}>
            <Container maxWidth={containerMaxWidth || Object.values(theme.container)}>
                <Grid gridColumnCount={12} gridGap={spacing(3)}>
                    <Box
                        columnCount={[2, null, 3]}
                        columnGap={spacing(3)}
                        role="list"
                        gridArea={["auto / auto / span 1 / span 12", null, "auto / auto / span 1 / span 6"]}
                    >
                        <MenuItem>
                            <Link href={routeToUrl("footer-zone/contacts")}>{t("ui-footer:contacts")}</Link>
                        </MenuItem>
                        {AppInstance.isCzech && (
                            <MenuItem>
                                <Link href={routeToUrl("footer-zone/memorandum")}>{t("ui-footer:memorandum")}</Link>
                            </MenuItem>
                        )}
                        <MenuItem>
                            <Link href={routeToUrl("footer-zone/data-protection")}>
                                {t("ui-footer:data-protection")}
                            </Link>
                        </MenuItem>
                        <Hide when={AppInstance.isSlovak}>
                            <MenuItem>
                                <Link href={routeToUrl("release-notes")}>{t("ui-footer:releases")}</Link>
                            </MenuItem>
                        </Hide>
                        <MenuItem>
                            <Link href="https://poradna.sousede.cz/" target="_blank">
                                {t("ui-footer:faq")}
                            </Link>
                        </MenuItem>
                        {AppInstance.isCzech && (
                            <MenuItem>
                                <Link href={routeToUrl("footer-zone/cookies")}>{t("ui-footer:cookies")}</Link>
                            </MenuItem>
                        )}
                        <MenuItem>
                            <Link href={UrlHelper.getTermsAndConditionsUrl()}>
                                {t("ui-footer:terms-and-condition")}
                            </Link>
                        </MenuItem>
                        {route !== "landing-zone/service-zone" && (
                            <MenuItem>
                                <Link href={routeToUrl("footer-zone/pricing")}>{t("ui-footer:pricing")}</Link>
                            </MenuItem>
                        )}
                        <Hide when={AppInstance.isSlovak}>
                            <MenuItem>
                                <Link href={routeToUrl("footer-zone/sitemap-web")}>{t("ui-footer:site-map")}</Link>
                            </MenuItem>
                        </Hide>
                        <MenuItem>
                            <Link href="https://reality.sousede.cz/" target="_blank" rel="noopener noreferrer">
                                {t("ui-footer:reality-portal")}
                            </Link>
                        </MenuItem>
                    </Box>
                    <Box
                        role="list"
                        gridArea={["auto / auto / span 1 / span 6", null, "auto / auto / span 1 / span 3"]}
                    >
                        <MenuItem>
                            <Link
                                href={
                                    AppInstance.isCzech
                                        ? "https://www.instagram.com/sousede.cz_official/"
                                        : "https://www.instagram.com/susedia.sk/"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FlexBox alignItems="center">
                                    <FlexBox alignItems="center" boxWidth="auto" flexShrink="0">
                                        <Icon name="instagram" size={IconSizeEnum.medium} />
                                    </FlexBox>
                                    <Text as="span" ml={spacing(1)}>
                                        Instagram
                                    </Text>
                                </FlexBox>
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link
                                href={
                                    AppInstance.isCzech
                                        ? "https://www.facebook.com/Sousede.CZ.SVJ.BD"
                                        : "https://www.facebook.com/susediaskk/"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FlexBox alignItems="center">
                                    <FlexBox alignItems="center" boxWidth="auto" flexShrink="0">
                                        <Icon name="facebook" size={IconSizeEnum.medium} />
                                    </FlexBox>
                                    <Text as="span" ml={spacing(1)}>
                                        Facebook
                                    </Text>
                                </FlexBox>
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link
                                href={
                                    AppInstance.isCzech
                                        ? "https://www.linkedin.com/company/soused%C3%A9-cz/"
                                        : "https://www.linkedin.com/company/spoločné-bývanie"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FlexBox alignItems="center">
                                    <FlexBox alignItems="center" boxWidth="auto" flexShrink="0">
                                        <Icon name="linkedIn" size={IconSizeEnum.medium} />
                                    </FlexBox>
                                    <Text as="span" ml={spacing(1)}>
                                        LinkedIn
                                    </Text>
                                </FlexBox>
                            </Link>
                        </MenuItem>
                        <Hide when={AppInstance.isSlovak}>
                            <MenuItem>
                                <Link
                                    href="https://www.youtube.com/channel/UC63PEUXSpoGf4Z-vy45ieZw"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FlexBox alignItems="center">
                                        <FlexBox alignItems="center" boxWidth="auto" flexShrink="0">
                                            <Icon name="youTube" size={IconSizeEnum.medium} />
                                        </FlexBox>
                                        <Text as="span" ml={spacing(1)}>
                                            YouTube
                                        </Text>
                                    </FlexBox>
                                </Link>
                            </MenuItem>
                        </Hide>
                        <Hide when={AppInstance.isCzech}>
                            <MenuItem>
                                <Link
                                    href="https://www.tiktok.com/@susedia.sk"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FlexBox alignItems="center">
                                        <FlexBox alignItems="center" boxWidth="auto" flexShrink="0">
                                            <Icon name="tikTok" size={IconSizeEnum.medium} />
                                        </FlexBox>
                                        <Text as="span" ml={spacing(1)}>
                                            TikTok
                                        </Text>
                                    </FlexBox>
                                </Link>
                            </MenuItem>
                        </Hide>
                    </Box>
                    <Box gridArea={["2 / 1 / span 1 / span 6", null, "1 / 1 / span 1 / span 3"]}>
                        <Logo boxHeight={41} boxWidth={129} id="footer-logo" preserveAspectRatio="xMinYMid slice" />
                        <TextLarge textColor="textMuted" textFontWeight="medium" mt={spacing(2)}>
                            &copy; {new Date().getFullYear()} {t("common:labels.sousede")}
                        </TextLarge>
                        <Link href="https://www.uxf.cz/" target="_blank">
                            <FlexBox
                                flexDirection={["column", null, null, "row"]}
                                alignItems={["flex-start", null, null, "center"]}
                                mt={spacing(2)}
                            >
                                <Text mr={spacing(1)} textColor="textMuted">
                                    {t("ui-footer:made-by")}
                                </Text>
                                <img src="/static/images/uxf.svg" alt="UX Fans" width="65px" />
                            </FlexBox>
                        </Link>
                    </Box>
                </Grid>
            </Container>
        </Root>
    );
};
